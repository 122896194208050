import Certificate4 from "../img/aarthi_cert_4.jpg";
import Certificate1 from "../img/aarthi_cert_1.jpg";
import Certificate2 from "../img/aarthi_cert_2.jpg";
import Certificate3 from "../img/aarthi_cert_3.jpg";


const certificateImages = [
  Certificate4,
  Certificate1,
  Certificate2,
  Certificate3
];

export default certificateImages;
