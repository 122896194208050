const allProjects = [
  {
    title: 'Fashionsh',
    img: require('../img/WP_projects/fashionsh.png'),
    description:
      'Fashionsh is a A WordPress-powered e-commerce website offering a seamless online shopping experience for customers.',
    websiteUrl: 'https://dev-aarthi.pantheonsite.io/',
    githubUrl: 'https://github.com/aarthipriya2021/fashionsh_website',
    techStack: ['WordPress', 'HTML5', 'CSS'],
  },
  {
    title: 'Texium',
    img: require('../img/WP_projects/Texium.png'),
    description:
      'Texium is a WordPress-based SaaS website catering to the life science and healthcare industries, offering comprehensive solutions research through innovative technology and tools.',
    // websiteUrl: 'https://animal-aid-thoriqas.vercel.app/',
    githubUrl: 'https://github.com/aarthipriya2021/saas_website_design',
    techStack: ['WordPress', 'HTML5', 'CSS'],
  },
  {
    title: 'Yum Yum Resto',
    img: require('../img/WP_projects/Yum Yum Resto/01-Home Page.png'),
    description:
      'Yum Yum Resto is a WordPress-powered SaaS website to promote online ordering, reservations, menu customization at Yum Yum Resto.',
    // websiteUrl: 'https://muhthoriqas.github.io/Google-Books-Search-ReactJS/',
    githubUrl: 'https://github.com/aarthipriya2021/yum_yum_resto',
    techStack: ['WordPress', 'HTML5', 'CSS'],
  },
  {
    title: 'Enchantia Jewels',
    img: require('../img/WP_projects/Enchantia jewels.png'),
    description:
      'Enchantia Jewels is a WordPress-powered SaaS website showcasing the fictional jewelry brand Enchantia Jewels. It features a captivating collection of jewelry pieces, offers an elegant design, and provides a user-friendly browsing experience.',
    websiteUrl: 'https://dev-enchantiajewels.pantheonsite.io/',
    githubUrl: 'https://github.com/aarthipriya2021/enchantiajewels_website/blob/main/enchantiajewels_FULL_PAGE.png',
    techStack: ['WordPress', 'HTML5', 'CSS'],
  },
  {
    title: 'Techax Labs',
    img: require('../img/WP_projects/techax labs.png'),
    description:
      'Techax Labs is a Re-designed WordPress-powered SaaS website to promote marine conservation, oceanic research, or offering courses and services related to the sea. ',
    // websiteUrl: 'https://muhthoriqas.github.io/Google-Books-Search-ReactJS/',
    githubUrl: 'https://github.com/aarthipriya2021/redesign_techax_labs/blob/main/techax-labs_FULL_PAGE.png',
    techStack: ['WordPress', 'HTML5', 'CSS'],
  },
];

export default allProjects;
