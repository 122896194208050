import { AiFillGithub, AiFillLinkedin, AiFillMail } from "react-icons/ai";
// import { AiFillGithub, AiFillLinkedin, AiFillInstagram, AiOutlineMail, AiFillMail } from "react-icons/ai";
import "./CSS/Footer.css";

function Footer() {
  return (
    <>
      <footer className="px-4">
        <div className="foot-left d-flex">
          <p>Aarthipriya Ayyappan &copy; 2023</p>
        </div>
        <div className="foot-right d-flex">
          <a
            href="https://github.com/aarthipriya2021"
            target="_blank"
            rel="noreferrer"
          >
            <AiFillGithub className="foot-icon" />
            &nbsp;&nbsp;GitHub
          </a>
          <a
            href="https://www.linkedin.com/in/aarthipriya-a-7729b6202/"
            target="_blank"
            rel="noreferrer"
          >
            <AiFillLinkedin className="foot-icon" />
            &nbsp;&nbsp;Linkedin
          </a>
          <a
            href="mailto:a.aarthipriya2023@gmail.com"
            target="_blank"
            rel="noreferrer"
          >
            <AiFillMail className="foot-icon" />
            &nbsp;&nbsp;E-mail
          </a>
        </div>
      </footer>
    </>
  );
}

export default Footer;
